import { useEffect } from 'react'
import DefaultLayout from 'components/layouts/default'
import styles from './index.module.scss'

const PageNotFound = (props) => {
  useEffect(() => {
    dataLayer.push({
      cd_pagetype: 'Sayfa Bulunamadı',
      cd_ad_id: 'undefined',
      cd_ad_name: 'undefined',
      cd_ad_company_name: 'undefined',
      cd_ad_location: 'undefined',
      cd_app_type: 'undefined',
      cd_search_location: 'undefined',
      cd_search_job_type: 'undefined',
      cd_list_position: 'undefined',
      cd_ad_company_sector: 'undefined',
      cm_ad_view: 'undefined',
      cm_list_count: 'undefined',
      cm_list_view: 'undefined',
      cm_app_download: 'undefined',
      cm_sms: 'undefined',
      cm_ad_apply: 'undefined',
      cm_ad_call: 'undefined',
    })
  }, [])
  return (
    <DefaultLayout
      title="Sayfa Bulunamadı"
      navbarClass="d-none d-lg-none"
      footerDisabled
      {...props}
    >
      <div className={`${styles.pageNotFound} page-content`}>
        <div className={styles.notfoundImage}>404</div>
        <h1>Sayfa bulunamadı!</h1>
        <p>Üzgünüz, ancak aradığın sayfayı bulamadık.</p>
        <a
          href={process.env.NEXT_PUBLIC_MAIN_URL2}
          className={`${styles.btn} btn-default btn-lg`}
        >
          ANA SAYFAYA DÖN
        </a>
      </div>
    </DefaultLayout>
  )
}
export default PageNotFound
